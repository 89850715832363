<template>
  <b-form-select
    :size="size"
    :value="value"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
    :required="required"
    :options="c_options"
  >
    <template #first>
      <b-form-select-option :value="null">Selecione...</b-form-select-option>
    </template>
  </b-form-select>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "SelectCommon",
  data() {
    return {
      optsCommon: []
    };
  },
  props: {
    value: {
      default: null
    },
    size: {
      type: String,
      default: "lg"
    },
    options: Array,
    required: Boolean,
    common: Object
  },
  computed: {
    c_options() {
      return this.getArray(this.common ? this.optsCommon : this.options).map(
        (item) => {
          return {
            value: item[this.common.optValue],
            text: item[this.common.optName]
          };
        }
      );
    }
  },
  mounted() {
    if (this.common) {
      this.getCommon();
    }
  },
  methods: {
    getCommon(params) {
      ApiService.get("common", this.common.resource, { params })
        .then(({ data }) => {
          this.optsCommon = this.getArray(data.response);
        })
        .catch(() => {
          this.clear();
        });
    },
    reload(params) {
      this.clear();
      this.getCommon(params);
    },
    clear() {
      this.value = null;
      this.optsCommon = [];
    },
    getArray(value) {
      return Array.isArray(value) ? value : [];
    }
  }
};
</script>
