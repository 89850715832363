<template>
  <div class="login-form">
    <form class="form" @submit.prevent="submit">
      <div class="pb-13 pt-lg-0 pt-5">
        <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
          Cadastrar
        </h3>
        <p class="text-muted font-weight-bold font-size-h4">
          Digite suas informações para criar sua conta
        </p>
      </div>
      <div class="form-group">
        <label>Nacionalidade:</label>
        <CSelectCommon
          :common="{
            resource: 'todos_paises',
            optValue: 'idpais',
            optName: 'nome'
          }"
          v-model="form.idpais_nacionalidade"
          required
        />
      </div>
      <div class="form-group" v-if="form.idpais_nacionalidade == 33">
        <label>CPF:</label>
        <KTInputMask
          type="text"
          v-model="form.numero_inscricao"
          mask="CPF"
          required
          autocomplete="off"
        />
      </div>
      <div class="form-group" v-else>
        <label>Número de inscrição:</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="form.numero_inscricao"
          required
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label>Nome completo:</label>
        <input
          type="text"
          class="form-control form-control-lg"
          v-model="form.nome"
          required
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label>Sexo:</label>
        <KTSelect :options="form_options.sexo" v-model="form.sexo" required />
      </div>
      <div class="form-group">
        <label>E-mail:</label>
        <input
          type="email"
          class="form-control form-control-lg"
          v-model="form.email"
          required
          autocomplete="off"
        />
      </div>
      <div class="form-group">
        <label>País:</label>
        <CSelectCommon
          :common="{ resource: 'pais', optValue: 'idpais', optName: 'nome' }"
          v-model="form.idpais"
          required
          @change="
            $refs.f_idestado.reload({ idpais: $event }) &&
              $refs.f_idmunicipio.clear()
          "
        />
      </div>
      <div class="form-group">
        <label>Estado/Província:</label>
        <CSelectCommon
          :common="{
            resource: 'estado',
            optValue: 'idestado',
            optName: 'nome'
          }"
          v-model="form.idestado"
          required
          ref="f_idestado"
          @change="$refs.f_idmunicipio.reload({ idestado: form.idestado })"
        />
      </div>
      <div class="form-group">
        <label>Cidade:</label>
        <CSelectCommon
          :common="{
            resource: 'municipio',
            optValue: 'idmunicipio',
            optName: 'nome'
          }"
          v-model="form.idmunicipio"
          required
          ref="f_idmunicipio"
        />
      </div>
      <div class="form-group">
        <label class="checkbox mb-0">
          <input type="checkbox" v-model="form.concorda_termos_condicoes" />
          <span class="mr-2"></span>
          Eu concordo com os
          <b-link @click="openTerms" class="ml-2"
            >termos e condições de uso</b-link
          >.
        </label>
      </div>
      <div class="form-group d-flex flex-wrap pb-lg-0 pb-3">
        <button
          type="submit"
          class="
            btn btn-primary
            font-weight-bolder font-size-h6
            px-8
            py-4
            my-3
            mr-4
          "
          style="width: 150px"
          :disabled="loading || !form.concorda_termos_condicoes"
          :class="{
            spinner: loading,
            'spinner-light': loading,
            'spinner-right': loading
          }"
        >
          Enviar
        </button>
        <b-btn
          class="font-weight-bolder font-size-h6 px-8 py-4 my-3"
          variant="light-primary"
          :to="{ name: 'login' }"
        >
          Cancelar
        </b-btn>
      </div>
    </form>
    <b-modal
      ref="modal-terms"
      size="xl"
      title="Termos e condições de uso"
      ok-only
      ok-variant="secondary"
      ok-title="Fechar"
    >
      <p v-html="terms"></p>
    </b-modal>
  </div>
</template>

<script>
import { PURGE_AUTH, REGISTER } from "@/core/store/auth.module";
import ApiService from "@/core/services/api.service.js";
import KTSelect from "@/view/components/form/Select.vue";
import CSelectCommon from "@/view/components/form/SelectCommon.vue";
import KTInputMask from "@/view/components/form/InputMask.vue";

export default {
  name: "Register",
  components: {
    KTSelect,
    CSelectCommon,
    KTInputMask
  },
  data() {
    return {
      loading: false,
      agree: false,
      form: {
        idpais_nacionalidade: null,
        numero_inscricao: null,
        nome: null,
        sexo: null,
        email: null,
        idpais: null,
        idestado: null,
        idmunicipio: null,
        concorda_termos_condicoes: false
      },
      form_options: {
        sexo: [
          {
            value: 1,
            text: "Masculino"
          },
          {
            value: 2,
            text: "Feminino"
          },
          {
            value: 3,
            text: "Outros"
          },
          {
            value: 4,
            text: "Não informado"
          }
        ]
      },
      terms: ""
    };
  },
  methods: {
    openTerms() {
      this.$refs["modal-terms"].show();
    },
    submit() {
      if (!this.loading) {
        // clear existing errors
        this.$store.commit(PURGE_AUTH);

        // set spinner to submit button
        this.loading = true;

        // send register request
        this.$store
          .dispatch(REGISTER, this.form)
          .then(() => {
            this.loading = false;
            this.form = {};
            this.$router.push({ name: "login" });
          })
          .catch(() => {
            this.loading = false;
          });
      }
    }
  },
  mounted() {
    ApiService.get("auth", "terms").then(({ data }) => {
      this.terms = data.response.terms;
    });
  }
};
</script>
